.App {
  text-align: center;
}

html, body, #root, #root>div {
  height: 100vh;
}

.bg-orange{
  background-color: #ff6b00;
}

.bg-black{
  background-color: #000000;
}

.bg-wv-1{
  background-color: #edf9fb;
}

.bg-wv-2{
  background-color: rgba(0,0,0,.85);
}

.bg-wv-3{
  background-color:#00acca
}

.text-orange {
  color: #dd6430;
}

.image-opacity {
  opacity: 0.3;
}

.next-button {
  background-color: #dd6430;
  border-radius: 80px;
  border-color: #edf9fb;
  font-weight: bold;
}

.next-button:not(:disabled):not(:disabled):active {
  background-color: #dd6430;
  border-radius: 80px;
  border-color: #edf9fb;
  font-weight: bold;
} 

.next-button:hover{
  background-color: #ff590f;
  border-color: black;
  transition: transform 0.2s;
  transform: scale(1.05);
}

.next-button:focus{
  background-color: #dd6430;
  border-color: white;
}

.next-button-disabled{
  background-color: gray;
  border-radius: 80px;
  border-color: #edf9fb;
  pointer-events: none;
}

.next-button-disabled:focus{
  background-color: gray;
  border-radius: 80px;
  border-color: #edf9fb;
  pointer-events: none;
}

.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}

.transform:hover {
  transition: transform 0.2s;
  transform: scale(1.1);
}