.finish-button {
    background-color: white;
    border-radius: 80px;
    border-color: #dd6430;
    font-weight: bold; 
    color:#dd6430;
}

.finish-button:not(:disabled):not(:disabled):active {
    background-color: white;
    border-radius: 80px;
    border-color: #dd6430;
    font-weight: bold; 
    color:#dd6430;
} 

.finish-button:hover{
    background-color: #ff590f;
    border-color: black;
  }
  
.finish-button:focus{
    background-color: white;
    border-color: #dd6430;
    color:#dd6430;
}