.slider-container {
    position: relative;
    text-align: center;
    cursor: pointer;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    color:  #dd6430;
    font-size: x-large;
    font-weight: bold;
}

.slick-prev:before {
    font-size: 30px;
}

.slick-next:before {
    font-size: 30px;
}


.slick-dots li button:before {
    color: #ff590f;
}

.slick-dots li.slick-active button:before{
    color: #ff590f;
}