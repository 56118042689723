.begin-experience-button {
  background-color: #dd6430;
  border-radius: 95px;
  border: none;
}

.begin-experience-button:hover{
  background-color: #ff590f;
  transition: transform 0.2s;
  transform: scale(1.10);
}

.begin-experience-button:active{
  background-color: #dd6430 !important;
}